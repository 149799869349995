import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "../../utils/windowUtils";

import DownArrowIcon from "../../assets/downarrow.svg";
import CopyIcon from "../../assets/Icons/copy-icon.svg";

import "../../styles/dataTable.scss";

// Helper function to get the paginated table rows
const getPaginatedRows = (totalRows, pageNo, pageSize) => {
    const initialIndex = pageNo * pageSize;
    const destnIndex = (pageNo + 1) * pageSize;
    return totalRows.slice(initialIndex, destnIndex);
};

// Helper function to determine if pagination is needed
const isPaginationNeeded = (totalRows, pageSize) => {
    return totalRows.length > pageSize;
};

// Helper function to dynamically sort rows given fieldName and order
const dynamicSort = (property, sortOrder) => {
    return (a, b) => {
        const fieldA =
            typeof a[property] === "string"
                ? a[property].toLowerCase()
                : a[property];
        const fieldB =
            typeof b[property] === "string"
                ? b[property].toLowerCase()
                : b[property];
        return (fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0) * sortOrder;
    };
};

const DataTable = (props) => {
    const {
        loading,
        columns = [],
        rows,
        noRowsComponent,
        pageSize,
        checkboxSelection,
        onSelectionModelChange,
        highlightedBox,
        userRole = "Member",
        openCopyDriveModal = () => null,
        isAllChecked,
    } = props;

    const ref = useRef();
    const [tableRows, setTableRows] = useState([]);
    const [startingIndex, setStartingIndex] = useState(-1);
    const [tableTotalRows, setTableTotalRows] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(0);
    const [allRowChecked, setAllRowChecked] = useState(false);
    const [copyIcon, setCopyIcon] = useState({
        targetIndex: -1,
        copy: false,
    });
    const [selectPopover, setSelectPopover] = useState(false);
    const everyRowChecked = tableRows.every((item) => item.checked);

    useOnClickOutside(ref, () => setSelectPopover(false));

    useEffect(() => {
        if (!isAllChecked && allRowChecked) {
            setAllRowChecked(false);
        }
    }, [isAllChecked]);

    useEffect(() => {
        const shouldResetPageNo = !isPaginationNeeded(tableTotalRows, pageSize);
        const newTableRows = getPaginatedRows(
            tableTotalRows,
            currentPageNo,
            pageSize
        );

        setTableRows([...newTableRows]);
    }, [tableTotalRows, currentPageNo]);

    useEffect(() => {
        const shouldResetPageNo = !isPaginationNeeded(rows, pageSize);

        if (!loading && shouldResetPageNo) {
            setCurrentPageNo(0);
        }
    }, [rows, loading]);

    useEffect(() => {
        const newTableTotalRows = [];
        rows.forEach((row) => {
            row["checked"] = allRowChecked;
            newTableTotalRows.push(row);
        });
        setTableTotalRows([
            ...newTableTotalRows.sort((a, b) =>
                sessionStorage.getItem("sort") === "asc"
                    ? a.score - b.score
                    : sessionStorage.getItem("sort") === "desc"
                    ? b.score - a.score
                    : 0
            ),
        ]);
    }, [rows]);

    useEffect(() => {
        const newTableColumns = [];
        columns.forEach((ntc) => {
            if (ntc.filterable) {
                ntc["showFilter"] = false;
            }
            newTableColumns.push(ntc);
        });
        setTableColumns([...newTableColumns]);
    }, [columns]);

    const handlePopover = () => setSelectPopover((prev) => !prev);

    const handleRowCheck = (e, i) => {
        const newTableRows = [...tableRows];
        const row = newTableRows[i];
        row["checked"] = e.target.checked;
        const selectedRows = [];
        newTableRows.forEach((ntr) => {
            if (ntr.checked) {
                selectedRows.push(ntr.id);
            }
        });
        setTableRows([...newTableRows]);
        onSelectionModelChange((prev) => {
            if (e.target.checked) return [...prev, row.id];
            return prev.filter((item) => item !== row.id);
        });
    };

    const handlePageRowCheck = () => {
        if (everyRowChecked) return;

        const newTableRows = [...tableRows];
        const selectedRows = [];
        newTableRows.forEach((ntr, i) => {
            newTableRows[i].checked = true;
            selectedRows.push(ntr.id);
        });
        setTableRows([...newTableRows]);
        onSelectionModelChange((prev) => [
            ...new Set([...prev, ...selectedRows]),
        ]);
    };

    const handleAllRowCheck = () => {
        setAllRowChecked((prev) => !prev);
        const newTableTotalRows = [...tableTotalRows];
        newTableTotalRows.forEach((ntr) => {
            ntr.checked = !allRowChecked;
        });
        const newTableRows = newTableTotalRows.slice(
            currentPageNo * pageSize,
            (currentPageNo + 1) * pageSize
        );
        setTableRows([...newTableRows]);
        setTableTotalRows([...newTableTotalRows]);
        onSelectionModelChange(allRowChecked ? [] : [...tableTotalRows]);
    };

    const sortColumn = (index) => {
        const newTableColumns = [...tableColumns];
        let newTableTotalRows = [];
        if (
            Object.prototype.hasOwnProperty.call(newTableColumns[index], "sort")
        ) {
            newTableTotalRows = [...tableTotalRows];
            newTableColumns[index]["sort"] =
                newTableColumns[index]["sort"] * -1;
        } else {
            newTableTotalRows = [...rows];
            newTableColumns[index]["sort"] = 1;
        }
        newTableColumns[index]["showFilter"] = false;
        newTableColumns.forEach((ntr, i) => {
            if (
                i !== index &&
                Object.prototype.hasOwnProperty.call(ntr, "sort")
            ) {
                delete ntr.sort;
            }
        });

        if (newTableColumns[index].sort > 0) {
            newTableTotalRows.sort(
                dynamicSort(newTableColumns[index].field, -1)
            );
            if (newTableColumns[index].field !== "name") {
                sessionStorage.setItem("sort", "desc");
            }
        } else {
            newTableTotalRows.sort(
                dynamicSort(newTableColumns[index].field, 1)
            );
            if (newTableColumns[index].field !== "name") {
                sessionStorage.setItem("sort", "asc");
            }
        }
        const newTableRows = newTableTotalRows.slice(0, pageSize);
        newTableRows.map((row) => ({ ...row, checked: false }));
        setCurrentPageNo(0);
        setTableColumns([...newTableColumns]);
        setTableRows([...newTableRows]);
        setTableTotalRows([...newTableTotalRows]);
    };

    const onToggleFilter = (e, index) => {
        e.stopPropagation();
        const newTableColumns = [...tableColumns];
        newTableColumns[index]["showFilter"] =
            !newTableColumns[index]["showFilter"];
        setTableColumns([...newTableColumns]);
    };

    const handleTableNavigation = (e) => {
        const value =
            startingIndex === -1 ? Number(e.target.value) : startingIndex;
        let tempArr = tableRows;
        if (e.key === "ArrowUp" && e.shiftKey) {
            const recentVal = Number(value);
            if (tempArr[recentVal].checked && recentVal !== 0) {
                tempArr[recentVal].checked = false;
            } else {
                tempArr[recentVal].checked = true;
            }
            setStartingIndex((prev) => {
                const newValue = recentVal - 1;
                if (newValue < 0) {
                    return prev;
                }
                return newValue;
            });
        } else if (e.key === "ArrowDown" && e.shiftKey) {
            let tempArr = tableRows;
            const recentVal = Number(value) + 1;
            if (recentVal < tableRows.length) {
                if (tempArr[recentVal].checked) {
                    tempArr[recentVal].checked = false;
                } else {
                    tempArr[recentVal].checked = true;
                }
                setStartingIndex(recentVal);
            }
        }

        setTableRows([...tempArr]);
    };

    return (
        <div
            className="datatable dataGridScroll"
            // style={{
            //     height: tableRows.length > 0 ? "auto" : "100%",
            // }}
        >
            <div className="datatable-header">
                {checkboxSelection ? (
                    <div className="row-checkbox">
                        <label>
                            <input type="checkbox" checked={allRowChecked} />
                            <span
                                className="checkmark"
                                onClick={handlePopover}
                                style={{
                                    pointerEvents: selectPopover
                                        ? "none"
                                        : "auto",
                                }}
                            />
                            <div
                                className="checkmark-content"
                                ref={ref}
                                style={{
                                    display: selectPopover ? "block" : "none",
                                }}
                            >
                                <p
                                    className="checkmark-content-text"
                                    onClick={handlePageRowCheck}
                                    style={{
                                        backgroundColor: everyRowChecked
                                            ? "#eee"
                                            : "",
                                        pointerEvents: everyRowChecked
                                            ? "none"
                                            : "auto",
                                    }}
                                >
                                    Select this page all records
                                </p>
                                <p
                                    className="checkmark-content-text"
                                    onClick={handleAllRowCheck}
                                >
                                    {allRowChecked ? "Unselect" : "Select"} all
                                    pages
                                </p>
                            </div>
                        </label>
                    </div>
                ) : null}
                {tableColumns.map((column, index) => {
                    return !column.hideColumn ? (
                        <div
                            className="row-header"
                            key={index}
                            style={{
                                justifyContent: column.headerAlign,
                                width: column.width ? column.width : "auto",
                                flex: column.width ? "none" : "",
                                zIndex: column.highlightHeader ? 1000 : 1,
                                position: "relative",
                                background: "#fff",
                            }}
                            onClick={() => sortColumn(index)}
                        >
                            {column.renderHeader
                                ? column.renderHeader()
                                : column.headerName}
                            <div className="tooltip">
                                <div
                                    className={`row-header-sort tooltip ${
                                        column.sort === 1
                                            ? "desc"
                                            : column.sort === -1
                                            ? "asc"
                                            : ""
                                    }`}
                                >
                                    <img src={DownArrowIcon} alt="arrow-icon" />
                                </div>
                                <span
                                    className="tooltiptext"
                                    style={{
                                        marginLeft: "-20px",
                                        minWidth: "fit-content",
                                    }}
                                >
                                    Sort
                                </span>
                            </div>
                            {column.filterable ? (
                                <>
                                    <div
                                        className="column-filter tooltip"
                                        onClick={(e) =>
                                            onToggleFilter(e, index)
                                        }
                                    >
                                        <div />
                                        <span
                                            className="tooltiptext"
                                            style={{
                                                marginLeft: "-20px",
                                                minWidth: "fit-content",
                                            }}
                                        >
                                            Filter
                                        </span>
                                    </div>
                                    {column.showFilter
                                        ? column.renderFilter(() =>
                                              sortColumn(index)
                                          )
                                        : null}
                                </>
                            ) : null}
                        </div>
                    ) : null;
                })}
            </div>
            {loading ? (
                <div className="table-loading-wrapper">
                    <div className="table-loader"></div>
                </div>
            ) : (
                <div
                    className="datatable-body"
                    tabIndex={2}
                    onKeyDown={handleTableNavigation}
                >
                    {tableRows.length ? (
                        <>
                            {tableRows.map((tableRow, i) => (
                                <div
                                    key={i}
                                    className={`datatable-row ${
                                        tableRow.checked ? "selected" : ""
                                    }`}
                                    style={{
                                        zIndex:
                                            highlightedBox && i === 0
                                                ? 1000
                                                : "auto",
                                        background:
                                            highlightedBox && i === 0
                                                ? "#fff"
                                                : tableRow.checked
                                                ? "#eff7ff"
                                                : "transparent",
                                    }}
                                    onMouseEnter={() =>
                                        setCopyIcon({
                                            targetIndex: i,
                                            copy: true,
                                        })
                                    }
                                    onMouseLeave={() =>
                                        setCopyIcon({
                                            targetIndex: i,
                                            copy: false,
                                        })
                                    }
                                >
                                    {checkboxSelection ? (
                                        <div className="row-checkbox">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={tableRow.checked}
                                                    onChange={(e) => {
                                                        handleRowCheck(e, i);

                                                        // TODO: if some indexes are already selected then unselect them all.
                                                        // setStartingIndex(Number(e.target.value));
                                                    }}
                                                    value={i}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    ) : null}
                                    {columns.map((column, j) => {
                                        return !column.hideColumn ? (
                                            <div
                                                key={j}
                                                style={{
                                                    textAlign:
                                                        column.headerAlign,
                                                    width: column.width
                                                        ? column.width
                                                        : "auto",
                                                    flex: column.width
                                                        ? "none"
                                                        : 1,
                                                    justifyContent:
                                                        column.headerAlign,
                                                    zIndex: column.highlightColumn
                                                        ? 1000
                                                        : "auto",
                                                    position: "relative",
                                                    background: tableRow.checked
                                                        ? "#eff7ff"
                                                        : "#fff",
                                                }}
                                            >
                                                {column.renderCell({
                                                    row: tableRow,
                                                })}
                                            </div>
                                        ) : null;
                                    })}
                                    {copyIcon.targetIndex === i &&
                                        copyIcon.copy &&
                                        userRole === "Admin" && (
                                            <img
                                                src={CopyIcon}
                                                alt="copy-icon"
                                                className="datatable-copy-icon"
                                                onClick={() =>
                                                    openCopyDriveModal(
                                                        tableRow.workflowId
                                                    )
                                                }
                                            />
                                        )}
                                </div>
                            ))}
                            <div className="datatable-footer">
                                <div className="datatable-footer-wrapper">
                                    <div className="datatable-footer-pageinfo">{`${
                                        currentPageNo * pageSize + 1
                                    }-${
                                        pageSize * (currentPageNo + 1) >
                                        rows.length
                                            ? rows.length
                                            : pageSize * (currentPageNo + 1)
                                    } of ${rows.length}`}</div>
                                    <div className="datatable-footer-navigation">
                                        <div
                                            className={`datatable-footer-prev ${
                                                currentPageNo ? "" : "disabled"
                                            }`}
                                            onClick={() =>
                                                setCurrentPageNo(
                                                    currentPageNo - 1
                                                )
                                            }
                                            style={{
                                                pointerEvents: currentPageNo
                                                    ? "auto"
                                                    : "none",
                                            }}
                                        />
                                        <div
                                            className={`datatable-footer-next ${
                                                currentPageNo >=
                                                rows.length / pageSize - 1
                                                    ? "disabled"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setCurrentPageNo(
                                                    currentPageNo + 1
                                                )
                                            }
                                            style={{
                                                pointerEvents:
                                                    currentPageNo >=
                                                    rows.length / pageSize - 1
                                                        ? "none"
                                                        : "auto",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        noRowsComponent
                    )}
                </div>
            )}
        </div>
    );
};

DataTable.propTypes = {
    loading: PropTypes.bool,
    columns: PropTypes.array,
    rows: PropTypes.array,
    noRowsComponent: PropTypes.element,
    pageSize: PropTypes.number,
    checkboxSelection: PropTypes.bool,
    onSelectionModelChange: PropTypes.func,
    highlightedBox: PropTypes.bool,
    userRole: PropTypes.string,
    openCopyDriveModal: PropTypes.func,
    isAllChecked: PropTypes.bool,
};

export default DataTable;
